<template>
  <CRow v-if="dataAvailable" class="h-100 m-0">
    <CCol cols="12" lg="12" class="p-0">
      <div class="h-100 highlight_stage d-flex">        
        <div v-if="highlightData[1]" class="h-100 position second">
          <div class="h-100 d-flex flex-column justify-content-end">
            <div class="text-center mb-1 item">
              <span @click="navigateToDetails(highlightData[1][idValue])" v-bind:class="{'pointer' : navigateTo}">{{highlightData[1][nameValue]}}</span>
            </div>
            <div class="p-3 stage">
              <div class="d-flex align-items-center justify-content-center award">
                <i class="fas fa-award"></i>
              </div>
              <div v-if="highlightData[1]" class="d-flex align-items-center justify-content-center item_score">
                <div v-if="showDecimals">
                  <span class="integral">{{ getScoreIntegral(highlightData[1][scoreValue]) }}</span>
                  <span class="decimal">.</span>
                  <span class="fractional">{{ getScoreFracetional(highlightData[1][scoreValue]) }}</span>
                </div>
                <div v-else>
                  <span>{{highlightData[1][scoreValue]}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>        
        <div v-if="highlightData[0]" class="h-100 position first">
          <div class="h-100 d-flex flex-column justify-content-end">
            <div class="text-center mb-1 item">
              <span @click="navigateToDetails(highlightData[0][idValue])" v-bind:class="{'pointer' : navigateTo}">{{highlightData[0][nameValue]}}</span>
            </div>
            <div class="p-3 stage">
              <div class="d-flex align-items-center justify-content-center award">
                <i class="fas fa-award"></i>
              </div>
              <div v-if="highlightData[0]" class="d-flex align-items-center justify-content-center item_score">
                <div v-if="showDecimals">
                  <span class="integral">{{ getScoreIntegral(highlightData[0][scoreValue]) }}</span>
                  <span class="decimal">.</span>
                  <span class="fractional">{{ getScoreFracetional(highlightData[0][scoreValue]) }}</span>
                </div>
                <div v-else>
                  <span>{{highlightData[0][scoreValue]}}</span>
                </div>                  
              </div>          
            </div>
          </div>
        </div>
        <div v-if="highlightData[2]" class="h-100 position third">
          <div class="h-100 d-flex flex-column justify-content-end">
            <div class="text-center mb-1 item">
              <span @click="navigateToDetails(highlightData[2][idValue])" v-bind:class="{'pointer' : navigateTo}">{{highlightData[2][nameValue]}}</span>
            </div>
            <div class="p-3 stage">
              <div class="d-flex align-items-center justify-content-center award">
                <i class="fas fa-award"></i>
              </div>
              <div v-if="highlightData[2]" class="d-flex align-items-center justify-content-center item_score">
                <div v-if="showDecimals">
                  <span class="integral">{{ getScoreIntegral(highlightData[2][scoreValue]) }}</span>
                  <span class="decimal">.</span>
                  <span class="fractional">{{ getScoreFracetional(highlightData[2][scoreValue]) }}</span>
                </div>
                <div v-else>
                  <span>{{highlightData[2][scoreValue]}}</span>
                </div>              
              </div>
            </div>
          </div>
        </div>
      </div>      
    </CCol>
  </CRow>
  <CRow v-else class="h-100">
    <CCol cols="12" xl="12" sm="12" class="pt-0 pb-0">
      <div class="h-100 w-100 d-flex align-items-center justify-content-center">
        <span class="text-center">{{ $t('insights.not_enough_highlight_data') }}</span>
      </div>
    </CCol>
  </CRow>  
</template>

<script>
export default {
  name: 'HighlightStage',
  props: ['stageData', 'scoreValue', 'nameValue', 'idValue', 'showDecimals', 'navigateTo'],
  watch: {
    $props: {
      handler() {
        if(this.stageData) {
          this.highlightData = this.getHighlights(this.stageData, 3);
          // Update the dataAvailable value if there is data available
          if(this.highlightData.length > 0) this.dataAvailable = true;
        }
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      dataAvailable: false,
      highlightData: [],
    }
  },
  methods: {
    getHighlights(array, amount) {
      var sortOnValue = this.scoreValue;
      // Clone the array to prevent infinite loops
      var highlightData = Object.values(array).slice(0);
      // Filter the highlights to make sure scores are available
      highlightData = highlightData.filter(i => ![null].includes(i[this.scoreValue]));
      // First sort the highlights on the average score
      highlightData.sort(function(x, y) {
        if(x[sortOnValue] == y[sortOnValue]) return 0;
        else if(parseFloat(x[sortOnValue]) < parseFloat(y[sortOnValue])) return 1;
        else return -1;
      });
      return highlightData.slice(0, amount);
    },
    getScoreIntegral(score) {
      if(typeof score === 'string' || score instanceof String) {
        return score.substring(0, score.indexOf('.'));
      } else {
        score = score.toFixed(1).toString();
        return score.substring(0, score.indexOf('.'));
      }
    },
    getScoreFracetional(score) {
      if(typeof score === 'string' || score instanceof String) {
        return score.substring(score.indexOf('.') + 1);
      } else {
        score = score.toFixed(1).toString();
        return score.substring(score.indexOf('.') + 1);
      }
    },
    navigateToDetails(id) {
      if(this.navigateTo) this.$router.push({path: `/insights/${this.navigateTo}/${id.toString()}`});
    }
  }
}
</script>